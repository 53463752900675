import React from 'react';
import Header from './components/Header'
import Content from "./components/Content";
function App() {
  return (
    // kitwindを使う
    <div>
      <div className='w-full fixed z-50 top-0'>
        <Header /></div>
      <div className='mt-12'>
        <Content /></div>
    </div>
  );
}

export default App;
